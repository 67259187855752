@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url("lato-v22-latin-regular.0fef7d9f.eot");
  src: local(""), url("lato-v22-latin-regular.0fef7d9f.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-regular.e6d78644.woff2") format("woff2"), url("lato-v22-latin-regular.cbbef634.woff") format("woff"), url("lato-v22-latin-regular.823e310b.ttf") format("truetype"), url("lato-v22-latin-regular.d22ab101.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url("lato-v22-latin-700.1ee483fe.eot");
  src: local(""), url("lato-v22-latin-700.1ee483fe.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-700.22c81854.woff2") format("woff2"), url("lato-v22-latin-700.5389f765.woff") format("woff"), url("lato-v22-latin-700.95bf6c89.ttf") format("truetype"), url("lato-v22-latin-700.c68fab10.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: url("lato-v22-latin-900.a841e472.eot");
  src: local(""), url("lato-v22-latin-900.a841e472.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-900.b7aab9b7.woff2") format("woff2"), url("lato-v22-latin-900.7e33f226.woff") format("woff"), url("lato-v22-latin-900.b9f16c75.ttf") format("truetype"), url("lato-v22-latin-900.73a0fcc5.svg#Lato") format("svg");
}

@font-face {
  font-family: Paytone One;
  font-style: normal;
  font-weight: 400;
  src: url("paytone-one-v16-latin-regular.3ca38c18.eot");
  src: local(""), url("paytone-one-v16-latin-regular.3ca38c18.eot#iefix") format("embedded-opentype"), url("paytone-one-v16-latin-regular.150113f2.woff2") format("woff2"), url("paytone-one-v16-latin-regular.a7485a9e.woff") format("woff"), url("paytone-one-v16-latin-regular.84541a5e.ttf") format("truetype"), url("paytone-one-v16-latin-regular.01242800.svg#PaytoneOne") format("svg");
}

.light-color-scheme {
  --color-white: #fff;
  --color-black: #000;
  --color-text: #111;
  --color-grey: #ccc;
  --color-almost-white: #eff0f0;
  --color-grey-light: #f2f2f2;
  --color-grey-darker: #899598;
  --color-button: var(--color-text);
  --color-button-background: var(--color-white);
  --color-border: var(--color-black);
  --color-primary: #d1fbff;
  --color-primary-alt: #00e9ff;
  --color-secondary: #b9ffec;
  --color-active-input: #0884ff;
  --color-link: var(--color-primary-alt);
  --color-red: #fc6c54;
  --color-red-light: #ffa0a0;
  --color-inactive: #ccc;
  --font-weight-bold: 600;
  --font-size-default: 16px;
  --font-size-small: .7rem;
  --font-size-large: 2.5rem;
  --font-family-monster: "Paytone One", Arial;
  --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  --color-background: var(--color-grey-light);
  --space-box: .95rem 1rem;
  --space-box-large: 1.25rem 1.5rem;
  --space-box-small: .5rem .75rem;
  --space-default: 1rem;
  --border-radius: 15px;
  --border-radius-small: 8px;
  --transition-duration: .2s;
  --navi-bottom-height: 3.5rem;
  --border-default: 2px solid var(--color-border);
  --border-button: var(--border-default);
  --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
  --text-shadow-button: -1px -1px 1px #ffffffb3;
  --color-scheme: inherit;
  --icon-checked: url("checked.95d10ab3.svg");
  --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
  --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-text: #111;
  --color-grey: #ccc;
  --color-almost-white: #eff0f0;
  --color-grey-light: #f2f2f2;
  --color-grey-darker: #899598;
  --color-button: var(--color-text);
  --color-button-background: var(--color-white);
  --color-border: var(--color-black);
  --color-primary: #d1fbff;
  --color-primary-alt: #00e9ff;
  --color-secondary: #b9ffec;
  --color-active-input: #0884ff;
  --color-link: var(--color-primary-alt);
  --color-red: #fc6c54;
  --color-red-light: #ffa0a0;
  --color-inactive: #ccc;
  --font-weight-bold: 600;
  --font-size-default: 16px;
  --font-size-small: .7rem;
  --font-size-large: 2.5rem;
  --font-family-monster: "Paytone One", Arial;
  --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  --color-background: var(--color-grey-light);
  --space-box: .95rem 1rem;
  --space-box-large: 1.25rem 1.5rem;
  --space-box-small: .5rem .75rem;
  --space-default: 1rem;
  --border-radius: 15px;
  --border-radius-small: 8px;
  --transition-duration: .2s;
  --navi-bottom-height: 3.5rem;
  --border-default: 2px solid var(--color-border);
  --border-button: var(--border-default);
  --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
  --text-shadow-button: -1px -1px 1px #ffffffb3;
  --color-scheme: inherit;
  --icon-checked: url("checked.95d10ab3.svg");
  --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
  --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@media (min-width: 600px) and (orientation: portrait), (min-height: 900px) and (orientation: landscape) {
  :root {
    --font-size-large: 4rem;
  }
}

@media (prefers-color-scheme: light) {
  body {
    --color-white: #fff;
    --color-black: #000;
    --color-text: #111;
    --color-grey: #ccc;
    --color-almost-white: #eff0f0;
    --color-grey-light: #f2f2f2;
    --color-grey-darker: #899598;
    --color-button: var(--color-text);
    --color-button-background: var(--color-white);
    --color-border: var(--color-black);
    --color-primary: #d1fbff;
    --color-primary-alt: #00e9ff;
    --color-secondary: #b9ffec;
    --color-active-input: #0884ff;
    --color-link: var(--color-primary-alt);
    --color-red: #fc6c54;
    --color-red-light: #ffa0a0;
    --color-inactive: #ccc;
    --font-weight-bold: 600;
    --font-size-default: 16px;
    --font-size-small: .7rem;
    --font-size-large: 2.5rem;
    --font-family-monster: "Paytone One", Arial;
    --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    --color-background: var(--color-grey-light);
    --space-box: .95rem 1rem;
    --space-box-large: 1.25rem 1.5rem;
    --space-box-small: .5rem .75rem;
    --space-default: 1rem;
    --border-radius: 15px;
    --border-radius-small: 8px;
    --transition-duration: .2s;
    --navi-bottom-height: 3.5rem;
    --border-default: 2px solid var(--color-border);
    --border-button: var(--border-default);
    --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
    --text-shadow-button: -1px -1px 1px #ffffffb3;
    --color-scheme: inherit;
    --icon-checked: url("checked.95d10ab3.svg");
    --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
    --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
    --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
    --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  }
}

body.force-light-color-scheme {
  --color-white: #fff;
  --color-black: #000;
  --color-text: #111;
  --color-grey: #ccc;
  --color-almost-white: #eff0f0;
  --color-grey-light: #f2f2f2;
  --color-grey-darker: #899598;
  --color-button: var(--color-text);
  --color-button-background: var(--color-white);
  --color-border: var(--color-black);
  --color-primary: #d1fbff;
  --color-primary-alt: #00e9ff;
  --color-secondary: #b9ffec;
  --color-active-input: #0884ff;
  --color-link: var(--color-primary-alt);
  --color-red: #fc6c54;
  --color-red-light: #ffa0a0;
  --color-inactive: #ccc;
  --font-weight-bold: 600;
  --font-size-default: 16px;
  --font-size-small: .7rem;
  --font-size-large: 2.5rem;
  --font-family-monster: "Paytone One", Arial;
  --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  --color-background: var(--color-grey-light);
  --space-box: .95rem 1rem;
  --space-box-large: 1.25rem 1.5rem;
  --space-box-small: .5rem .75rem;
  --space-default: 1rem;
  --border-radius: 15px;
  --border-radius-small: 8px;
  --transition-duration: .2s;
  --navi-bottom-height: 3.5rem;
  --border-default: 2px solid var(--color-border);
  --border-button: var(--border-default);
  --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
  --text-shadow-button: -1px -1px 1px #ffffffb3;
  --color-scheme: inherit;
  --icon-checked: url("checked.95d10ab3.svg");
  --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
  --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

html {
  font-size: var(--font-size-default);
}

body {
  font-family: var(--font-family);
  color: var(--color-text);
  background: var(--color-white);
  line-height: 1.25em;
}

*, :after, :before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
}

a {
  color: var(--color-link);
}

.text-center {
  text-align: center;
}

.small-and-gentle-hint {
  color: var(--color-grey);
  text-align: center;
  padding: var(--space-box);
  font-size: .8rem;
}

.button {
  border: var(--border-button);
  border-radius: var(--border-radius);
  text-transform: uppercase;
  transition: background-color var(--transition-duration);
  background: var(--color-button-background);
  box-shadow: var(--box-shadow-form-elements);
  color: var(--color-button);
  text-shadow: var(--text-shadow-button);
  padding: .5rem 1rem;
  font-size: .9rem;
  font-weight: 900;
  transition: transform .1s;
  display: inline-block;
}

.button:hover, .button:active {
  background: var(--color-primary);
  cursor: pointer;
}

.button:hover {
  text-shadow: 1px 1px #fff;
  transform: translate(-1px, -1px);
  box-shadow: 2px 2px #0003;
}

.button.active, .button.primary {
  background-color: var(--color-secondary);
}

.button.secondary {
  background: var(--color-primary);
}

.button.small {
  padding: .25rem .75rem;
  font-size: .8rem;
}

a.button {
  text-decoration: none;
}

form.form {
  background: var(--color-background);
  padding: var(--space-box);
  border: var(--border-default);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-form-elements);
}

form.form input {
  padding: var(--space-box-small);
  border: var(--border-default);
  border-radius: var(--border-radius-small);
  font-size: 1rem;
  font-family: var(--font-family);
  width: 100%;
  color: var(--color-text);
  background: var(--color-white);
  color-scheme: var(--color-scheme);
}

form.form > label {
  padding-bottom: var(--space-default);
  display: block;
}

form.form label > div {
  padding-bottom: calc(.5 * var(--space-default));
}

form.form button[role], form.form a.button {
  border-radius: var(--border-radius-small);
  width: 100%;
  color: var(--color-button);
  text-align: center;
  margin-top: .5rem;
}

form.form button[role="submit"] {
  background-color: var(--color-secondary);
}

form.form a.button.delete {
  background-color: var(--color-red-light);
  color: #6f0000;
  width: 100%;
  text-align: center;
  border-radius: var(--border-radius-small);
  display: block;
}

form.form .form-message {
  padding: var(--space-default) 0;
}

form.form .form-message.error {
  color: var(--color-red);
}

form.form .form-message {
  text-align: center;
  font-weight: bold;
}

form.form .form-message:last-child {
  padding-bottom: 0;
}

form.form .form-message:first-child {
  padding-top: 0;
}

form.form a.close {
  justify-content: flex-end;
  display: flex;
}

form.form a.close img {
  width: 1.5rem;
  box-shadow: 2px 2px 0px var(--color-white);
  background: #fff;
  border-radius: 50%;
  transition: transform .1s;
}

form.form a.close img:hover {
  box-shadow: none;
  transform: translate(1px, 1px);
}

form.form .actions {
  text-align: center;
  padding: var(--space-box);
  display: block;
}

form.form label.with-checkbox {
  justify-content: space-between;
  display: flex;
}

form.form input[type="checkbox"] {
  height: 1.25rem;
  width: 1.25rem;
  background: var(--color-white);
  color: currentColor;
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: .15em solid;
  border-radius: .15em;
  margin: 0;
  padding: 0;
  transform: scale(1.1);
}

form.form input[type="checkbox"]:hover {
  cursor: pointer;
}

form.form input[type="checkbox"]:before {
  content: "✓";
  color: #0000;
  width: 1em;
  text-align: center;
  font-size: .85rem;
  font-weight: bold;
  line-height: 1em;
  display: block;
}

form.form input[type="checkbox"]:checked:before {
  color: currentColor;
}

form.form select, select.select {
  border: var(--border-default);
  background: var(--color-white);
  border-radius: var(--border-radius-small);
  padding: var(--space-box-small);
  appearance: none;
  text-align: center;
  background: var(--icon-chevron-down) 90% / min(12%, 1rem) no-repeat var(--color-white);
  color: var(--color-text);
  padding-right: 3rem;
  font-size: 1rem;
}

form.form select {
  width: 100%;
}

form.form textarea, textarea.textarea {
  border: var(--border-default);
  background: var(--color-white);
  border-radius: var(--border-radius-small);
  padding: var(--space-box-small);
  width: 100%;
  color: var(--color-text);
  font-size: 1rem;
}

textarea:focus, input:focus, button[type="submit"] {
  outline-color: var(--color-active-input);
}

body.content-page {
  background: var(--color-white);
  display: block;
}

body.content-page.centered {
  place-content: center;
  display: grid;
}

body.content-page footer {
  opacity: .6;
  text-align: center;
  padding: var(--space-default) 0;
}

body.content-page footer a {
  color: var(--color-grey-darker);
  font-family: Paytone One, Arial;
  text-decoration: none;
}

body.content-page #top-navi {
  background-color: var(--color-white);
  width: 100%;
  z-index: 1000;
  padding: 1rem 3rem;
  position: sticky;
  top: 0;
  box-shadow: 0 0 5px #0000000d;
}

body.content-page #top-navi nav {
  place-content: center;
  gap: .5rem;
  display: flex;
}

body.content-page #top-navi.with-logo {
  height: 3rem;
  background: var(--image-lettering-horizontal-one-line) center no-repeat;
  background-size: contain;
}

.gradient-text {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(286deg, #bd00ff, #0063ff);
  background-size: 100%;
  background-clip: text;
}

.top-logo {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
}

body.frontpage {
  text-align: center;
  background-color: var(--color-almost-white);
  --color-link: #1fb7c8;
  display: block;
}

body.frontpage .content-container {
  text-align: left;
}

body.frontpage .content-container h1 {
  font-size: 1.7rem;
  font-weight: 800;
  line-height: 1.3em;
}

body.frontpage .content-container h2 {
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: bold;
}

#frontpage main > * {
  width: min(30rem, 90vw);
  margin: 0 auto;
  padding: 2rem 0;
}

#top-navi li {
  list-style: none;
}

#top-navi .button {
  text-transform: none;
}

#top-navi .button[href*="/signup"] {
  text-transform: uppercase;
}

#opener {
  position: relative;
}

#opener img {
  margin-top: clamp(3rem, 20vw, 8.5rem);
  margin-left: min(10%, 10rem);
  padding-top: 2rem;
}

#opener a {
  color: var(--color-text);
}

h2 {
  color: var(--color-text);
  font-size: var(--font-size-large);
  line-height: 1.5em;
  font-family: var(--font-family-monster);
  margin: 0 0 .5em;
}

#opener-lettering {
  font-family: var(--font-family-monster);
  z-index: -1;
  letter-spacing: -2px;
  text-align: center;
  margin: 0 auto;
  font-size: clamp(4rem, 10vw, 6.5rem);
  line-height: .9em;
  position: absolute;
  left: 10%;
}

.feature-box ul {
  max-width: 30em;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.feature-box li {
  border-radius: var(--border-radius);
  background: var(--color-white);
  border: 4px solid #eee;
  margin: 0 0 2rem;
  padding: 2rem 3rem;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.25em;
  list-style: none;
}

.feature-box li img {
  width: 6rem;
  margin-bottom: 1rem;
}

.feature-box li video {
  width: 100%;
  object-fit: contain;
  border: 2px solid #eee;
  box-shadow: 0 15px 15px #00000026;
}

.feature-box li .plyr {
  border: 2px solid #eee;
  border-radius: 10px;
  margin-top: 2rem;
  overflow: hidden;
  box-shadow: 0 15px 15px #00000026;
}

.feature-box li .plyr video {
  box-shadow: none;
  border: none;
}

.feature-box li .plyr {
  --plyr-range-fill-background: #01dc7e;
  --plyr-color-main: #01dc7e;
}

.feature-box li .plyr__control--overlaid {
  opacity: 1;
}

.feature-box li .plyr__video-wrapper {
  background: #222;
}

@media (min-width: 600px) and (orientation: portrait), (min-height: 900px) and (orientation: landscape) {
  .plyr video {
    box-shadow: none;
    border: none;
  }

  .feature-box li:hover {
    cursor: pointer;
  }

  .feature-box li:hover .video-player {
    opacity: 1;
    pointer-events: inherit;
  }

  .feature-box li .video-player {
    width: min(30rem, 40vw);
    object-fit: contain;
    opacity: 0;
    height: inherit;
    border: none;
    border-radius: 0;
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

/*# sourceMappingURL=frontpage.a536d934.css.map */
