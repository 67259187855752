@import 'base';
@import 'form';

body.content-page {
    display: block;
    background: var(--color-white);
    &.centered {
        display: grid;
        place-content: center;
    }
    footer {
        opacity: 0.6;
        text-align: center;
        padding: var(--space-default) 0;
        a {
            font-family: 'Paytone One', Arial;
            color: var(--color-grey-darker);
            text-decoration: none;
        }
    }
    #top-navi {
        background-color: var(--color-white);
        padding: 1rem 3rem;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1000;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
        nav {
            display: flex;
            gap: 0.5rem;
            place-content: center;
        }
        &.with-logo {
            height: 3rem;
            background: var(--image-lettering-horizontal-one-line) center
                no-repeat;
            background-size: contain;
        }
    }
}
