/* www.fasting.monster */
@import 'contentpage';

.gradient-text {
    /* background-image: linear-gradient(176deg, #00ee83, #00a9e7); */
    background-image: linear-gradient(286deg, #bd00ff, #0063ff);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.top-logo {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
}
body.frontpage {
    display: block;
    text-align: center;
    background-color: var(--color-almost-white);
    --color-link: #1fb7c8;

    .content-container {
        text-align: left;
        h1 {
            line-height: 1.3em;
            font-size: 1.7rem;
            font-weight: 800;
        }
        h2 {
            font-family: inherit;
            font-size: 1.3rem;
            font-weight: bold;
        }
    }
}

#frontpage main > * {
    padding: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* width: 40rem;
    max-width: 90%; */
    width: clamp(30rem, 10rem, 90vw);
    margin: 0 auto;
}

#top-navi {
    li {
        list-style: none;
    }

    .button {
        text-transform: none;
    }
    .button[href*="/signup"] {
        text-transform: uppercase;
    }
}

#opener {
    position: relative;

    img {
        margin-left: min(10%, 10rem);
        padding-top: 2rem;
        margin-top: clamp(3rem, 20vw, 8.5rem);
    }
    a {
        color: var(--color-text);
    }
}

h2 {
    /* @reuse .gradient-text; */
    color: var(--color-text);
    font-size: var(--font-size-large);
    line-height: 1.5em;
    margin: 0;
    margin-bottom: 0.5em;
    font-family: var(--font-family-monster);
}

#opener-lettering {
    margin: 0 auto;
    position: absolute;
    font-family: var(--font-family-monster);
    font-size: clamp(4rem, 10vw, 6.5rem);
    left: 10%;
    z-index: -1;
    line-height: 0.9em;
    letter-spacing: -2px;
    text-align: center;
}

.feature-box {
    ul {
        max-width: 30em;
        margin: 0 auto;
        text-align: center;
        padding: 0;
    }
    li {
        list-style: none;
        font-weight: 900;
        margin: 0;
        padding: 2rem 0;
        font-size: 1.5rem;
        line-height: 1.25em;
        border-radius: var(--border-radius);
        background: var(--color-white);
        padding: 2rem 3rem;
        margin-bottom: 2rem;
        border: 4px solid #eee;

        img {
            width: 6rem;
            margin-bottom: 1rem;
        }
        video {
            width: 100%;
            box-shadow: 0 15px 15px rgba(0, 0, 0, 0.15);
            object-fit: contain;
            border: 2px solid #eee;
            /* border-radius: 2px; */
            /* overflow: hidden; */
        }
        .plyr {
            box-shadow: 0 15px 15px rgba(0, 0, 0, 0.15);
            border: 2px solid #eee;
            margin-top: 2rem;
            border-radius: 10px;
            overflow: hidden;
            video {
                border: none;
                box-shadow: none;
            }
            --plyr-range-fill-background: #01dc7e;
            --plyr-color-main: #01dc7e;
        }
        .plyr__control--overlaid {
            opacity: 1;
        }
        .plyr__video-wrapper {
            background: #222;
        }
    }
}

/* desktop */
@media (min-width: 600px) and (orientation: portrait),
    (min-height: 900px) and (orientation: landscape) {
    .plyr {
        video {
            border: none;
            box-shadow: none;
        }
    }
    .feature-box {
        li {
            &:hover {
                cursor: pointer;
                .video-player {
                    opacity: 1;
                    pointer-events: inherit;
                }
            }
            .video-player {
                position: fixed;
                right: 0;
                bottom: 0;
                width: clamp(10rem, 30rem, 40vw);
                object-fit: contain;
                border-radius: 5px;
                opacity: 0;

                border: none;
                border-radius: 0;
                height: inherit;

                display: block;
            }
        }
    }
}
